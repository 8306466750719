import { defineComponent, reactive, ref, watch, computed } from '@vue/composition-api';
import { useAuthorized } from '@/shared/composables/useAuthorized';
import { UserRoleState } from '@/generated-types/graphql.types';
import { redirectUserToPrev } from '@/Authentication/utils/user';
import { AuthPopups } from '@/Authentication/authentication.const';
import Login from '@/Authentication/Login.vue';
import SignUp from '@/Authentication/SignUp.vue';
import CompleteAccount from '@/Authentication/CompleteAccount.vue';
import SelectRole from '@/Authentication/SelectRole.vue';
import EmailLogin from '@/Authentication/components/EmailLogin.vue';
import EmailConfirmation from '@/Authentication/components/EmailConfirmation.vue';
import EmailConfirmed from '@/Authentication/components/EmailConfirmed.vue';
import AnotherBrowser from '@/Authentication/components/AnotherBrowser.vue';
import PhoneConfirmed from '@/Authentication/components/PhoneConfirmed.vue';
import Phone from '@/Authentication/components/Phone.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { BookingRoutes } from '@/Booking/booking.const';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    components: {
        Login,
        SignUp,
        CompleteAccount,
        SelectRole,
        EmailLogin,
        EmailConfirmation,
        EmailConfirmed,
        AnotherBrowser,
        PhoneConfirmed,
        Phone
    },
    setup(_, context) {
        const { root } = context;
        const toast = useToast();
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const loginData = reactive({
            accountId: null,
            val: ''
        });
        const verifiedEmail = ref('');
        const verifiedPhone = ref('');
        const accountData = reactive({
            val: '',
            accountId: null
        });
        const signUpData = reactive({
            accountId: null
        });
        const isAuthorized = useAuthorized();
        const authPopups = reactive({
            set selectRole(val) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: val ? AuthPopups.SELECT_ROLE : undefined
                    }
                });
            },
            get selectRole() {
                return AuthPopups.SELECT_ROLE === root.$route.query.authPopup;
            },
            set completeAccount(val) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: val ? AuthPopups.COMPLETE_ACCOUNT : undefined
                    }
                });
            },
            get completeAccount() {
                return AuthPopups.COMPLETE_ACCOUNT === root.$route.query.authPopup;
            },
            set signUp(val) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: val ? AuthPopups.SIGN_UP : undefined
                    }
                });
            },
            get signUp() {
                return AuthPopups.SIGN_UP === root.$route.query.authPopup;
            },
            set login(val) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: val ? AuthPopups.LOGIN : undefined
                    }
                });
            },
            get login() {
                return root.$route.query.authPopup === AuthPopups.LOGIN;
            },
            set phoneConfirmed(_) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            },
            get phoneConfirmed() {
                return root.$route.query.authPopup === AuthPopups.PHONE_CONFIRMED;
            },
            set phoneConfirmation(_) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            },
            get phoneConfirmation() {
                return root.$route.query.authPopup === AuthPopups.PHONE_CONFIRMATION;
            },
            set emailConfirmation(_) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            },
            get emailConfirmation() {
                return root.$route.query.authPopup === AuthPopups.EMAIL_CONFIRMATION;
            },
            set emailConfirmed(_) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            },
            get emailConfirmed() {
                return root.$route.query.authPopup === AuthPopups.EMAIL_CONFIRMED;
            },
            set anotherBrowser(_) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            },
            get anotherBrowser() {
                return root.$route.query.authPopup === AuthPopups.ANOTHER_BROWSER;
            },
            set emailLogin(_) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            },
            get emailLogin() {
                return root.$route.query.authPopup === AuthPopups.EMAIL_LOGIN;
            },
            set phoneLogin(_) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            },
            get phoneLogin() {
                return root.$route.query.authPopup === AuthPopups.PHONE_LOGIN;
            }
        });
        const watchAccountId = (accountId, prevAccountId) => {
            if (accountId && accountId !== prevAccountId) {
                wsAuth(accountId);
            }
        };
        const handlePhoneConfirmationSubmit = (phone) => {
            verifiedPhone.value = phone;
        };
        const handleLoginSubmit = ({ accountId, val }) => {
            loginData.accountId = accountId;
            loginData.val = val;
            loginData.accountId = accountId;
        };
        const handleCompleteAccountSubmit = ({ phone, accountId }) => {
            accountData.val = phone;
            accountData.accountId = accountId;
        };
        const cleanAuthData = () => {
            loginData.accountId = null;
            loginData.val = '';
            signUpData.accountId = null;
            accountData.val = '';
            accountData.accountId = null;
        };
        const handleSignUpSubmit = (data) => Object.keys(data).map(key => (signUpData[key] = data[key]));
        watch(() => root.$route.query, () => {
            const queryToken = root.$route.query.token;
            const queryVerifyEmail = root.$route.query['verify-email'];
            const queryVerifyPhoneWithLink = root.$route.query['verify-phone-with-link'];
            const queryEmailToken = root.$route.query.email_token;
            const queryRedirectUrl = root.$route.query.redirect_url;
            if (queryToken && process.browser) {
                globalThis.$store
                    .dispatch('authentication/loginByToken', queryToken)
                    .then(data => {
                    if (queryRedirectUrl && typeof queryRedirectUrl === 'string') {
                        globalThis.$router.push(useGetLocalizedPath(queryRedirectUrl));
                    }
                    else if (data.account_state === UserRoleState.Vendor) {
                        if (!data.organisation_id) {
                            globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                        }
                        else {
                            globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${BookingRoutes.VENDOR}`));
                        }
                    }
                    else {
                        globalThis.$router.replace({ query: { token: undefined } });
                        redirectUserToPrev();
                    }
                })
                    .catch(() => {
                    toast.error(root.$i18n.t('login.errors.invalid_auth_link'));
                    globalThis.$router.replace({ query: { token: undefined } });
                });
            }
            if (queryVerifyEmail && process.browser) {
                globalThis.$store
                    .dispatch('authentication/verifyEmail', queryVerifyEmail)
                    .then(email => {
                    verifiedEmail.value = email;
                    globalThis.$store.dispatch('$_app/refetchUser');
                    globalThis.$router.replace({
                        query: {
                            'verify-email': undefined,
                            authPopup: AuthPopups.EMAIL_CONFIRMED
                        }
                    });
                })
                    .catch(() => {
                    toast.error(root.$i18n.t('login.errors.invalid_auth_link'));
                    globalThis.$router.replace({
                        query: { 'verify-email': undefined }
                    });
                });
            }
            if (queryVerifyPhoneWithLink && process.browser) {
                globalThis.$store
                    .dispatch('authentication/verifyPhoneLink', queryVerifyPhoneWithLink)
                    .then(phone => {
                    verifiedPhone.value = phone;
                    globalThis.$store.dispatch('$_app/refetchUser');
                    globalThis.$router.replace({
                        query: {
                            'verify-phone-with-link': undefined,
                            authPopup: AuthPopups.PHONE_CONFIRMED
                        }
                    });
                })
                    .catch(() => {
                    toast.error(root.$i18n.t('login.errors.invalid_auth_link'));
                    globalThis.$router.replace({
                        query: { 'verify-phone-with-link': undefined }
                    });
                });
            }
            // Auto login with redirect
            if (queryEmailToken && process.browser) {
                globalThis.$store
                    .dispatch('authentication/loginWithRedirect', queryEmailToken)
                    .then(() => globalThis.$router.replace(queryRedirectUrl));
            }
        }, { immediate: true });
        const handleRoleSelectionClose = () => {
            toast.info(root.$i18n.t('login.default_role_text'));
            onModalClose();
        };
        watch(() => signUpData.accountId, watchAccountId);
        watch(() => loginData.accountId, watchAccountId);
        watch(() => accountData.accountId, watchAccountId);
        const wsAuth = (accountId) => {
            const socketUri = `${process.env.VUE_APP_PUBLIC_WS_URL}${accountId}/`;
            const socket = new WebSocket(socketUri);
            socket.addEventListener('open', () => {
                console.log('Waiting for token validation.');
            });
            socket.addEventListener('message', async ({ data }) => {
                const response = JSON.parse(data);
                if (response.event === 'authorization') {
                    const { data: userData } = response;
                    globalThis.$store.dispatch('authentication/authorize', {
                        accessToken: userData.access_token,
                        refreshToken: userData.refresh_token,
                        userData
                    });
                    globalThis.$router.replace({
                        query: {
                            ...root.$route.query,
                            authPopup: AuthPopups.ANOTHER_BROWSER
                        }
                    });
                    cleanAuthData();
                    socket.close();
                    if (userData.account_state === UserRoleState.Vendor &&
                        !userData.organisation_id &&
                        root.$route.query.authPopup !== AuthPopups.ANOTHER_BROWSER) {
                        globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                    }
                    return;
                }
                if (response.event === 'verification') {
                    const { data: userData } = response;
                    verifiedPhone.value = userData.phone;
                    globalThis.$router.replace({
                        query: {
                            ...root.$route.query,
                            authPopup: AuthPopups.PHONE_CONFIRMED
                        }
                    });
                    cleanAuthData();
                    socket.close();
                    return;
                }
                console.log('Unknown event received through websocket.', response);
            });
            socket.onerror = error => {
                console.log('ERROR', error);
            };
            socket.onclose = error => {
                console.log('SOCKET CONNECTION CLOSED', error);
            };
        };
        watch(() => root.$route.query, ({ authPopup }) => {
            if (AuthPopups.SIGN_UP === authPopup && isAuthorized.value) {
                globalThis.$router.replace({
                    query: {
                        ...root.$route.query,
                        authPopup: undefined
                    }
                });
            }
        }, { immediate: true });
        watch(isAuthorized, (next, prev) => {
            if (prev !== next && next) {
                const { authPopup } = root.$route.query;
                if (authPopup &&
                    [
                        AuthPopups.LOGIN,
                        AuthPopups.EMAIL_LOGIN,
                        AuthPopups.EMAIL_CONFIRMATION,
                        AuthPopups.PHONE_LOGIN
                    ].includes(authPopup)) {
                    globalThis.$router.replace({
                        query: {
                            ...root.$route.query,
                            'verify-email': undefined,
                            authPopup: undefined,
                            token: undefined
                        }
                    });
                }
            }
        }, { immediate: true });
        const onModalClose = () => {
            // clean up query params
            globalThis.$router.replace({
                query: {}
            });
        };
        return {
            accountData,
            authPopups,
            loginData,
            signUpData,
            user,
            verifiedEmail,
            verifiedPhone,
            handleCompleteAccountSubmit,
            handleLoginSubmit,
            handlePhoneConfirmationSubmit,
            handleRoleSelectionClose,
            handleSignUpSubmit,
            onModalClose
        };
    }
});
